<template>
  <section id="pricing" class="pricing padding">
    <div class="container">
      <div class="pricing__content">
        <h2 class="section-heading">Pricing</h2>
        <p class="section-subheading">
          Take advantage of the introductory pricing
        </p>
        <div class="section-block">
          <div class="section-block__price-cards">
            <div class="section-block__price-card">
              <p class="section-block__price-card-title">Subscription</p>
              <div class="section-block__price-card-main">
                <p class="section-block__price-card-subtitle">from</p>
                <div class="section-block__price-info">
                  <p class="section-block__price_dollar">$</p>
                  <p class="section-block__price_count">2.50</p>
                  <p class="section-block__price_period">/mo</p>
                </div>
                <p class="section-block__price-card-bill">billed annually</p>
              </div>
            </div>
            <div class="section-block__price-card section-block__price-card_lt">
              <p class="section-block__price-card-title">Lifetime</p>
              <div class="section-block__price-card-main">
                <div class="section-block__price-info">
                  <p class="section-block__price_dollar">$</p>
                  <p class="section-block__price_count">99</p>
                </div>
                <p class="section-block__price-card-bill">billed once</p>
              </div>
            </div>
          </div>
          <GetExtBtn />
        </div>
        <div class="bg-img-one rotation"></div>
        <div class="bg-img-two rotation-two"></div>
      </div>
    </div>
  </section>
</template>

<script>
import GetExtBtn from "@/components/GetExtensionButton";

export default {
  components: {
    GetExtBtn
  }
}
</script>

<style scoped>
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotation-two {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

* {
  color: #fff;
}

.padding {
  padding: 0;
}

.pricing {
  background: linear-gradient(93.61deg, #3350c4 16.59%, #6059f7 94.36%);
  overflow: hidden;
}

.container {
  width: 100%;
  max-width: 1224px;
  margin: 0 auto;
}

.pricing__content {
  padding: 80px 0 86px;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.bg-img-one {
  height: 375px;
  width: 358px;
  background-image: url(../../images/price-one.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: -10px;
  right: -80px;
  z-index: -1;
  transform: rotate(-145deg);
}

.bg-img-two {
  height: 375px;
  width: 358px;
  background-image: url(../../images/price-two.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: -10px;
  left: -80px;
  z-index: -1;
  transform: rotate(-145deg);
}

.rotation {
  animation-name: rotation;
  animation-duration: 32s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.rotation-two {
  animation-name: rotation-two;
  animation-duration: 38s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.section-heading {
  font-size: 48px;
  line-height: 62px;
  text-align: center;
  letter-spacing: -0.02em;
}

.section-subheading {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  margin: 16px 0 48px;
}

.section-block {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 24px;
  width: 584px;
  height: 354px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
}

.section-block__price-cards{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 24px;
  margin-bottom: 40px;
}

.section-block__price-card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 256px;
  height: 210px;
  background: #FFFFFF;
  border: 1px solid rgba(41, 44, 46, 0.1);
  border-radius: 10px;
  padding: 20px 0 28px;
}

.section-block__price-card-title{
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #3D3D3D;
}

.section-block__price-card-main{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.section-block__price-card-subtitle{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #17191D;
  margin-bottom: -4px;
}

.section-block__price-info{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}

.section-block__price_dollar{
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #6159F7;
  align-self: start;
  margin: 0 2px 0 0;
  padding: 0;
}

.section-block__price_count{
  font-weight: 600;
  font-size: 64px;
  line-height: 62px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #6159F7;
  margin: 0;
  padding: 0;
}
.section-block__price_period{
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #6159F7;
  align-self: end;
  margin: 0;
  padding: 0;
}

.section-block__price-card-bill{
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
}

.section-block__extensions{
  display: flex;
  flex-direction: row;
  column-gap: 10px;

}

.section-block__extension {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  width: auto;
  height: 56px;
  background-color: #6159F7;
  border-radius: 10px;
}

.section-block__extension-firefox {
  background-color: #3080F2;
}

.section-block__extension-img {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
.section-block__extension-text {
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #FFFFFF;
}

.section-block__extension:hover{
  opacity: 0.8;
}

@media screen and (max-width: 800px) {
  .bg-img-one {
    top: 190px;
    height: 250px;
    width: 240px;
    opacity: 0.6;
  }

  .bg-img-two {
    top: -150px;
    height: 250px;
    width: 240px;
    opacity: 0.6;
  }
}
@media screen and (max-width: 640px) {
  .section-heading {
    font-size: 36px;
    line-height: 44px;
  }
  .section-subheading {
    max-width: 343px;
  }
  .pricing__content {
    padding: 80px 0;
  }
  .section-block {
    width: 339px;
    height: 502px;
    padding: 16px;
  }

  .section-block__price-cards{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 12px;
    margin-bottom: 18px;
  }

  .section-block__price-card{
    width: 307px;
    height: 202px;
    padding: 20px 0 24px;
  }

  .section-block__price-card_lt {
    height: 182px;
  }

}
</style>
