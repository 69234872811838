<template>
  <footer class="footer">
    <div class="wrapper">
      <router-link to="/" class="footer__logo">
        <img src="@/images/logo-white.svg" />
      </router-link>
      <nav class="footer__navigation">
          <router-link to="/sign-in" class="footer__link">Login</router-link>
          <router-link to="/#pricing" class="footer__link">Pricing</router-link>
          <router-link to="/help" class="footer__link">Help</router-link>
          <router-link to="/privacy" class="footer__link">Privacy policy</router-link>
          <router-link to="/tos" class="footer__link">Terms of service</router-link>
        </nav>
      <div class="footer__copyright">
        Made in New Zealand 🇳🇿 by <a class="footer__copyright-link" href="https://karmabot.chat/" target="_blank">Karma</a> team. © {{ currentYear }} All rights reserved.
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
};
</script>

<style scoped>

.footer {
  background: #202629;
  position: relative;
}
.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
  width: 100%;
  max-width: 1224px;
  margin: 0 auto;
}

.footer__logo {
  width: 172px;
  height: 24px;
  z-index: 2;
  position: relative;
}

.footer__navigation{
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 20px;
}

.footer__link{
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #fff;
}

.footer__logo:hover, .footer__link:hover {
  opacity: 0.8;
}

.footer__copyright {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #fff;
}
.footer__copyright-link{
  border-bottom: 1px solid #fff;
}

.footer__copyright-link:hover {
  opacity: 0.8
}

@media screen and (max-width: 1200px) {
  .wrapper {
    position: relative;
    padding-bottom: 60px;
  }
  .footer__copyright {
    position: absolute;
    bottom: 20px;
    right: 0;
    left: 0;
  }
}

@media screen and (max-width: 660px) {
  .wrapper {
    padding: 30px 0 70px;
    flex-direction: column;
    gap: 20px;
  }
  .footer__copyright {
    bottom: 30px;
  }
}

@media screen and (max-width: 470px) {
  .wrapper {
    padding-bottom:90px;
    align-items: center;
    margin: auto;
  }
  /*.footer__navigation{
    flex-direction: column;
    max-height: 100px;
    flex-wrap: wrap;
    column-gap: 60px;
    width: 150px;
    align-items: start;
  }*/
  .footer__link{
    max-width: 60px;
  }
  .footer__copyright {
    max-width: 260px;
    right: auto;
    left: auto;
  }
}

</style>
