import Landing from '@/pages/Landing'
import SignIn from '@/pages/SignIn'
import Welcome from '@/pages/Welcome'
import Palettes from '@/pages/Palettes'
import Profile from '@/pages/Profile'
//import Expired from "@/pages/Expired";
import Palette from "@/pages/Palette"
import Help from "@/pages/Help"
import InstalFf from "@/pages/InstallFf"
import Privacy from "@/pages/Privacy"
import Tos from "@/pages/Tos"
import NotFound from "@/pages/NotFound"
import store from '@/store/index.js';
import { createRouter, createWebHistory } from 'vue-router'
import { extensionId } from '../utils/constants';

const routes = [
  {
    path: "/",
    name: 'homepage',
    component: Landing,
  },
  {
    path: "/sign-in",
    name: 'sign-in',
    component: SignIn,
  },
  {
    path: "/welcome",
    component: Welcome,
  },
  {
    path: "/palettes",
    component: Palettes,
  },
  {
    path: "/profile",
    name: 'profile',
    component: Profile,
  },
  {
    path: "/install-ff",
    name: 'install-ff',
    component: InstalFf,
  },
  /*{
    path: "/expired",
    name: 'expired',
    component: Expired,
  },*/
  {
    path: "/palette",
    name: 'palette',
    component: Palette,
  },
  {
    path: "/help",
    name: 'help',
    component: Help,
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy
  },
  {
    path: '/tos',
    name: 'tos',
    component: Tos
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: NotFound
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/not-found' },
];

const router = createRouter({
	routes,
	history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
      }
    }
    return { left: 0, top: 0 }
  }
})

router.beforeEach(async (to, from) => {
  // profitwell
  if(store.state.user)
    window.profitwell('start', { 'user_email': store.state.user.email});

  const arrayUnAuthPages =['sign-in', 'homepage', 'help', 'palette', 'privacy', 'tos', 'install-ff']
  if ((!arrayUnAuthPages.includes(to.name) && !store.state.userToken)){
    router.push({ path: '/sign-in' });
  }
  if (to.name === 'sign-in' && store.state.userToken) {
    router.push({ path: '/profile' });
    if (typeof chrome === "undefined" || !chrome.runtime) {
      localStorage.setItem('palette.site_login', store.state.userToken);
      window.postMessage("externally_connectable.login")
    } else if (chrome.runtime) {
      chrome.runtime.sendMessage(extensionId, {
        command: "palette.site_login",
        token: store.state.userToken
      });
    }
    // router.push({ path: '/profile' });
  }
})
export default router
